<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' v-model="edata.id">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Page</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                <div id="esms">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id='ename' required v-model="edata.name">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Link
                    </label>
                    <input type="text" class="form-control" id='ename' v-model="edata.link">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Note
                    </label>
                    <input v-model="edata.note" type="text" id="enote"  class="form-control">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Phone
                    </label>
                    <input v-model="edata.phone" type="text" id="ephone"  class="form-control">
                </div>
                <div class="form-group mb-1">
                        <label for="">Image</label>
                        <div class="w-100">
                            <input id="ephoto" @change="changeImg('photo',$event)" type="file">
                            <button @click="resetImg('photo')" type="button" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                            <img v-if="phoo" :src="photo" class="my-2 w-25 rounded-circle">
                        </div>
                    </div>
                </div>
                  <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="@/assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            photo : {
                type : [String, Object , Boolean],
                default : null
            },
            edata : {
                type : [String, Object , Boolean],
                default : []
            },
            phoo : {
                type : [String, Object, Boolean],
                default : null
            },
            pho : {
                type : [String, Object, Boolean],
                default : null
            }
        },
        data(){
            return {
                tbl : "pages",
                per : "pos_page",
                isUpdate : false,
            }
        },
        methods: {  
            triggerReset(){
                // $('#editModal').on('hidden.bs.modal', function () {
                //     $(this).find('form').trigger('reset');
                // })
                this.isUpdate = false;
            },
            resetImg(value){
                if(value == "photo"){
                    $('#ephoto').val('');
                    this.$emit("resetImg");
                }
            },
            changeImg(value,obj){
                if(value == "photo"){
                    this.$emit("changeImg",obj);
                }
            },
            async form_update(){
                this.isUpdate = true;
                let data = new FormData();
                data.append('id',this.edata.id);
                data.append('tbl',this.tbl);
                data.append('per',this.per);
                data.append('name',this.edata.name ? this.edata.name : '');
                data.append('note',this.edata.note ? this.edata.note : '');
                data.append('link',this.edata.link ? this.edata.link : '');
                data.append('logo',this.phoo);
                data.append('phone',this.edata.phone);
                await frm_update(data);
                this.triggerReset();
                this.$emit("editModal",$('#edit_status').val());   
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>