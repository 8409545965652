<template>
    <content-header title="POS Invoice" icon="fa fa-angle-double-right"/>
    <content>
      <loading v-if="isTrue"/>
      <div v-else class="container-fluid">
        <div class="card">
  
  
  
          <!-- button action -->
          <div class="card-header">
            <!-- <button v-if="create" class="btn btn-system btn-sm mb-2" data-toggle='modal' data-target='#createModal'>
              <i class="fa fa-plus-circle"></i> CREATE
            </button>
            &nbsp;
            <button v-if="deleteAll && table.data.length" class="btn btn-danger btnBlock btn-sm mb-2" @click="alertRemove('actionDeleteAll','plural')">
              <i class="fa fa-trash"></i> Delete All
              <input type="hidden" id="delete_data">
            </button>
            <input id="actionDeleteAll" type="hidden" @click="btnDeleteAll()">
            &nbsp; -->
            <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>      
  
            
            &nbsp;
            <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
              <option value="DESC">ORDER BY : NEWEST</option>
              <option value="ASC">ORDER BY : OLDEST</option>
            </select>
           
            <div class="row">
              <div class="col-12">
                <div class="form-group float-sm-right mb-0 mt-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="far fa-calendar-alt"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control float-right" id="reservation">
                    <div class="input-group-prepend" @click="clickRangeDate()">
                        <span class="input-group-text rounded-right border-left-0">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </span>
                    </div>
                </div>
            </div>
              </div>
            </div>
          </div>
  
  
  
          <div class="card-body">
  
  
  
            <!-- button search and option data  -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                Show
                <label> 
                  <select v-model="table.per_page" @change="btnPage('per_page')"  data-live-search="false" class="selectPicker form-control form-control-sm">
                    <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                  </select> 
                </label>
                entries
              </div>
              <div class="col-sm-12 col-md-6 col-12">
                  <div class="float-md-right">
                    Search:
                    <label>
                      <input v-model="search" type="search" class="form-control form-control-sm">
                    </label>
                  </div>
              </div>
            </div>
  
  
  
  
            <!-- table  -->
            <div class="table-responsive position-relative">
              <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
                <thead class="bg-system text-light">
                  <tr>
                    <th style="width:50px" class="d-none"></th>
                    <th style="width:50px">#</th>
                    <th>Barcode</th>
                    <th>Date</th>
                    <th>Page</th>
                    <th>Delivery</th>
                    <th>Customer</th>
                    <th>Phone</th>
                    <th>Location</th>
                    <th>Shipping</th>
                    <th>Sub Total</th>
                    <th>Total</th>
                    <!-- <th>Paid</th> -->
                    <!-- <th>Debt</th> -->
                    <th>Sale</th>
                    <th>Payment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="table.data.length">
                  <tr v-for="(data, index) in table.data" :key="data.id" :id="`tr${data.id}table`">
                    <td class="d-none"></td>
                    <td>{{ table.from_index + index}}</td>
                    <td :data-col="`#`+ (table.from_index+index) + `. Code`">
                      <template v-for="invDe in invoiceDetails" :key="invDe.id">
                        <template v-if="invDe.invoice_id == data.id">
                          <template v-for="pro in products" :key="pro.id">
                            <span v-if="pro.id == invDe.pos_product_id">{{ pro.barcode }}(x{{ invDe.quantity }}) <br> </span>
                          </template>
                        </template>
                      </template>
                      <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                        <i class="fas fa-plus-circle"></i>
                      </button>
                    </td>
                    <td data-col="Date">{{ new Date(data.date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : '2-digit', 'hour' : 'numeric', "minute" : "numeric" }) }} </td>
                    <td data-col="Page">{{ data.page_name }}</td>
                    <td data-col="Delivery">{{ data.delivery_name }}</td>
                    <td data-col="Customer">{{ data.cus_name }}</td>
                    <td data-col="Phone">{{ data.cus_phone }}</td>
                    <td data-col="Location">{{ data.location }}</td>
                    <td data-col="Shipping">$ {{ formatToCurrency(data.shipping) }}</td>
                    <td data-col="Sub Total USD">$ {{ formatToCurrency(Number(data.total_usd) - Number(data.shipping)) }}</td>
                    <td data-col="Total USD">$ {{ formatToCurrency(data.total_usd) }}</td>
                    <!-- <td data-col="Paid">$ {{ formatToCurrency(data.paid) }}</td> -->
                    <!-- <td data-col="Debt">$ {{ formatToCurrency(data.debt) }}</td> -->
                    <td data-col="Sale Status">
                        <span class="rounded btn btn-xs text-white" 
                            :class="
                                data.sale_status == 1 ? 'bg-system' :
                                data.sale_status == 2 ? 'bg-danger' :
                                'bg-info'
                            "
                            style="cursor:auto;"
                        >
                            {{
                                data.sale_status == 1 ? 'Completed' :
                                data.sale_status == 2 ? 'Voided' :
                                'changed'
                            }}
                        </span>
                    </td>
                    <td data-col="Payment Status">
                          <!-- sale -->
                          <!-- <span class="rounded btn btn-xs text-white mb-2" 
                                :class="
                                    data.sale_status == 1 ? 'bg-system' :
                                    data.sale_status == 2 ? 'bg-danger' :
                                    'bg-info'
                                "
                                style="cursor:auto;"
                            >
                                {{
                                    data.sale_status == 1 ? 'Completed' :
                                    data.sale_status == 2 ? 'Voided' :
                                    'changed'
                                }}
                            </span> -->
                            <!-- payment  -->
                        <span class="rounded btn btn-xs text-white" 
                            :class="
                                data.paid_status == 1 ? 'bg-dark' :
                                data.paid_status == 2 ? 'bg-danger' :
                                'bg-warning'
                            "
                            style="cursor:auto;"
                        >
                            {{
                                data.paid_status == 1 ? 'Paid' :
                                data.paid_status == 2 ? 'UnPaid' :
                                'Due'
                            }}
                        </span>
                    </td>

                    <td data-col="Action">
                      <!-- <button v-if="edit" type='button' @click="btnEdit(data.id)"  :button="`edit${data.id}`"
                          class='btn btn-success btn-xs mr-1' title="edit">
                          <img :id="`editImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                          <i :id="`editFa${data.id}`" class='fa fa-edit'></i>
                      </button> -->
                      
                      <div class="dropdown dropleft">
                        <button :button="`edit${data.id}`" class="btn btn-outline-system-xs btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                          <img :id="`editImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                          <span :id="`editFa${data.id}`">Action</span>
                        </button>
                        <div class="dropdown-menu">
                          <div
                            class="dropdown-item text-sm txt-system"
                            @click="btnEdit(data.id, 'print')"
                            :button="`edit${data.id}`"
                          >
                            <img :id="`editImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                            <span :id="`editFa${data.id}`">Print Invoice <i class="fa-solid fa-print"></i></span>
                          </div>
                          <div 
                            v-if="data.sale_status != 2"
                            class="dropdown-item text-sm text-success"
                            @click="btnEdit(data.id, 'payment')"
                            :button="`edit${data.id}`"
                          >
                            <img :id="`editImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                            <span :id="`editFa${data.id}`">Payment <i class="fa-solid fa-sack-dollar"></i></span>
                          </div>
                          <div v-if="(data.paid_status != 2) && (deleteAll && data.paid_status == 2 && data.sale_status == 1)" class="dropdown-divider"></div>
                          <div v-if="(deleteAll && data.paid_status == 2 && data.sale_status == 1)" class="dropdown-item text-sm text-danger" @click="alertRemove(data.id,'single')">
                            <img :id="`deleteImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                            <span>Void <i :id="`deleteFa${data.id}`" class='fa-regular fa-circle-xmark'></i></span>
                          </div>
                          
                        
                          <input :id="`hiddenDelete${data.id}`" type="hidden" @click="btnDelete(data.id)">
                        </div>
                      </div>
                    </td>
                  </tr>    
                </tbody>
              </table>
            </div>
             <!-- Processing loader  -->
                
              <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                  <div class="card-body">
                  <span class="txt-system">Processing...</span>
                  </div>
              </div>
  
              <!-- no data -->
              <div v-if="!table.data.length" class="card">
                  <div class="card-body text-center">
                    <span class="txt-system">No Data...</span>
                  </div>
              </div>
      
  
  
            <!-- button table  -->
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
              </div>
              <div class="col-sm-12 col-md-7">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <ul class="pagination">
                    <li  class="page-item mr-3">
                      <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                       <template v-if="table.totalItem != 0">
                        <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                       </template>
                      </select>
                    </li>
                    <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                      <button  :disabled="table.page == 1 || table.totalIndexPage == 0"  type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                    </li>
                    <li @click="btnPage('next',this.table.page+1)" class="page-item">
                      <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
  
          
  
  
          </div>
        </div>
      </div>
    </content>
    <content-header/>

    <teleport to="#print">
      <print
        v-if="printInvoices"
        :printInvoices="printInvoices"
      />
      <p v-else style="text-align:center">No Last Invoice Data !!!</p>
    </teleport>

    <PaymentView
      :ecompanies="ecompanies"
      :edata="edata"
      :edetails="edetails"
      :paymentMethod="paymentMethod"
      :exchangeRate="exchangeRate"
      @createModal="JustCreateModal($event)"
      @updateModal="JustUpdateModal($event)"
    />
  </template>
  
  <script>
  import axios from 'axios'
  import { invoicePos , voidInvoice } from '@/networks/services/pos/invoicePos.service'
  import { checkPermission } from '@/networks/services/helper/helper.js'
  import Loading from '@/components/loading/Loading'
  import { config } from '@/networks/config'
  import { remove, bulk_delete , check} from '@/networks/services/helper/button.js'
  import PaymentView from '@/views/Pos/invoices/PaymentView'
  import print from '@/components/sale/print'
  
  
  
  
  export default {
    components: { 
      Loading,
      PaymentView,
      print
    },
    data(){
      return {
        products : [],
        invoiceDetails: [],
        table : {
          page: 1,
          totalIndexPage: 0,
          totalItem: 0,
          per_page: 10,
          from_index:0,
          to_index:0,
          data: [],
          search: null,
          orderBy: 'DESC'
        },
        edata : [],
        ecompanies : {},
        edetails : [],
        printInvoices : false,
        search: null,
  
        permission_no: false,
        isTrue: true,
        pageLoad: false,
        create: false,
        deleteAll : false,
        edit: false,
        list: false,
        paymentMethod : [],
        exchangeRate : {},
        fromDate : '',
        toDate : '',
        timming : 0,
      }
    },
    computed:{
      selectOptions(){
          let opts = this.table.totalIndexPage;
          return opts
      }
    },
    watch: {
      search(after, before){  
        clearTimeout(this.timming);
        this.timming = setTimeout(() => {
          this.table.search = this.search
          this.pageLoad = true;
          this.table.page = 1;
          this.getData(false);  
        }, 1000);
      },
    }
    ,
    methods : {
      formatToCurrency(amount, fixed = 2) {
        if (fixed > 0) {
            return Number(amount)
                        .toFixed(fixed)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            let luy = Number(amount)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            let new_luy = luy.split(".");

            return new_luy[0];
        }
      },
      reloadData(){
          this.table.orderBy = 'DESC';
          this.table.per_page = 10;
          this.table.page = 1;
          this.isTrue = true;
          this.getData(false);
      },
      btnOrderBy(){
        this.pageLoad = true;
        this.getData(false);
      },
      JustCreateModal(event){
        if(event == "success"){
          if(this.table.data.length){
              this.getData(false);
              this.btnEdit(this.edata.id,"payment");
          } else {
              this.isTrue = true;
              this.getData(false);
          }
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      JustUpdateModal(event){
        if(event == "success"){
          this.getData(false);
          this.btnEdit(this.edata.id,"payment");
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      btnEdit(id, condition){
        
        $(`[button=edit${id}]`).prop("disabled", true);
        $(`#editImg${id}`).toggleClass('d-none');
        $(`#editFa${id}`).toggleClass('d-none');
        $('#editModal').on('hidden.bs.modal', function () {
          $(this).find('form').trigger('reset');
        })
        
        this.getData(id,condition); 
      },
      alertRemove(id,type=null){
        alertify.confirm('Void Invoice', 'Are you sure to do this stuff ?', function(){ 
          if(type == "single"){
            $(`#hiddenDelete${id}`).trigger("click");
          }
          if(type == "plural"){
            $(`#${id}`).trigger("click");
          }
        }
        , function(){ });
      },
      async btnDelete(id){
        axios 
        .get(`${voidInvoice(id)}`)
        .then((response)=>{
          if(response.data.status == 'success'){
            alertify.set('notifier','position', 'top-right');
            alertify.success(`${response.data.message}`);
            this.pageLoad = true;
            this.getData(false);
          }
          else if (response.data.status == 'error'){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${response.data.message}`);
          }
          else if(response.data.status === 401) {
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${response.data.message}`);
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');
          } else if(response.data.status == "is_permission_no"){
            this.isTrue = true;
            this.getData(false);
          }
          else {
            console.log('something wrong with api');
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      clickRangeDate(){
          this.check_date = '',
          this.fromDate = $('#reservation').data('daterangepicker').startDate.format("YYYY-MM-DD");
          this.toDate = $('#reservation').data('daterangepicker').endDate.format("YYYY-MM-DD");
          this.pageLoad = true;
          this.getData(false);
      },
      async btnDeleteAll(){
        let obj = {
          tbl : "invoices",
          per : "pos_invoice"
        };
        let event = await bulk_delete(obj);
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          $('#checkBox').prop('checked',false);
          this.isTrue = true;
          this.getData(false);
        }
      },
      btnChecky(obj){
        check(obj);
      },
      btnPage(e,value = null){
        this.pageLoad = true;
        if(e == 'prev'){
          this.table.page = value;
          this.getData(false);
        } 
        if(e == 'next'){
          this.table.page = value;
          this.getData(false);
        } else if(e == 'per_page'){
          this.table.page = 1;
          this.getData(false);
        } else {
          this.getData(false);
        }
      },    
      totalPage(){
        return this.table.totalIndexPage;
      },
      async reloadSelectOption(){
        await this.$nextTick();
        $('.selectPicker').selectpicker('refresh');
        $('.selectPicker').selectpicker();
      },
      async getData(id,condition = ''){
        if(localStorage.getItem('posUser')){
          config();
          axios
            .get(`${invoicePos(this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}&fromDate=${this.fromDate}&toDate=${this.toDate}`)
            .then(response => {
              if(response.data.status === 'success'){
                if(!id){
                  this.pageLoad = false;
                  
                  //table
                  this.table.totalIndexPage = response.data.data.last_page;
                  this.table.data = response.data.data.data; 
                  this.table.totalItem = response.data.data.total;
                  this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                  this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                  //product
                  this.products = response.data.products;

                  //invoice Detail
                  this.invoiceDetails = response.data.invoiceDetails;

        
                  //permission
                  this.list = response.data.list;
                  this.create = response.data.create;
                  this.deleteAll = response.data.delete;
                  this.edit = response.data.edit;

                  this.isTrue = false;
                  $('#checkBox').prop('checked',false);

                } else {
                  //printInvoices
                  this.printInvoices = response.data.print_invoices;


                  //payment
                  this.edata = response.data.data.checkInvoice;
                  this.ecompanies = response.data.print_invoices.company;
                  this.edetails = response.data.data.payment_detail;

                  //payment methods
                  this.paymentMethod = response.data.data.payment_methods;

                  //exhcange rate
                  this.exchangeRate = response.data.data.exchangeRate;

                  $('#eid').val(response.data.data.id);
                  $(`#editImg${id}`).toggleClass('d-none');
                  $(`#editFa${id}`).toggleClass('d-none');
                  $(`[button=edit${id}]`).prop("disabled", false);

                  if(condition == 'payment'){
                    $('#editModal').modal({backdrop: 'static', keyboard: false}, 'show');
                  }
                  if(condition == 'print'){
                    this.$nextTick(()=>{
                      setTimeout(() => {
                        window.print();
                      }, 10);
                    })
                  }
                }
                  
              } else if(response.data.status === 401) {
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${response.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
              } else if(response.data.status == "is_permission_no"){
                this.permision_no = true;
              }
              else {
                console.log('something wrong with api');
              }
            })
            .then(()=>{
              this.reloadSelectOption();
              $('#reservation').daterangepicker({
                  autoUpdateInput: true,
                  locale: {
                      cancelLabel: 'Clear',
                      format: 'DD/MM/YYYY'
                  },
              });      
              $('#reservation').on('apply.daterangepicker', function(ev, picker) {
                  $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
              });

              $('#reservation').on('cancel.daterangepicker', function(ev, picker) {
                  $(this).val('');
              });
            })
            .catch(error => {
                console.log(error)
              }
            );
        } else {
          this.$router.push('/Unauthenticated')
        }
      },
    },
    created(){
      this.getData(false);
    },
    mounted(){
      setTimeout(() => {
        $('#belugar').attr('media',"screen");
      }, 100);
      $('head').append('<link id="removeLink" rel="stylesheet" href="/assets/style/posPrint.css">');
    },
    updated(){

    }
  }
  </script>
  
  <style>
  
  </style>