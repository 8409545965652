<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Customer</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                <div id="esms">
                </div>
                <div class="form-group mb-1">
                    <label for="ename">
                        Code <span class="text-danger">*</span>
                    </label>
                    <input v-model="edata.code" type="text" class="form-control" id='ecode' readonly required>
                </div>
                <div class="form-group mb-1">
                    <label for="ename">
                        Name <span class="text-danger">*</span>
                    </label>
                    <input v-model="edata.name" type="text" class="form-control" id='ename' required>
                </div>
                <div class="form-group mb-1">
                    <label for="ephone">
                        Phone <span class="text-danger">*</span>
                    </label>
                    <input v-model="edata.phone" type="text" class="form-control" id='ephone' required>
                </div>
                <div class="form-group mb-1">
                    <label for="ephone">
                        Address <span class="text-danger">*</span>
                    </label>
                    <input v-model="edata.address" type="text" class="form-control" id='ephone' required>
                </div>
                <div class="form-group mb-1">
                    <label for="ecustomerType_id">
                        Customer Type <span class="text-danger">*</span>
                    </label>
                    <select v-model="edata.customer_type_id" id="ecustomerType_id" class="border-page-color selectPicker form-control txt-kh" data-live-search="false" required>
                        <option :value="customerType.id" v-for="customerType in customerTypes" :key="customerType.id"> {{ customerType.name }} ( Discount {{ customerType.discount + customerType.type }} ) </option>
                    </select>
                </div>
                </div>
                  <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            edata : {
                type : [Object],
                default : []
            },
            customerTypes : {
                type : [Object],
                default : []
            },
        },
        data(){
            return {
                tbl : "customers",
                per : "customer",
                isUpdate : false,
            }
        },
        methods: {  
            triggerReset(){
                // $('#editModal').on('hidden.bs.modal', function () {
                //     $(this).find('form').trigger('reset');
                // })
                this.isUpdate = false;
            },
            async form_update(){
                this.isUpdate = true;
                let data = {
                    id: $('#eid').val(),
                    tbl : this.tbl,
                    per : this.per,
                    name : this.edata.name,
                    phone : this.edata.phone,
                    address : this.edata.address,
                    customer_type_id : this.edata.customer_type_id,
                };
                await frm_update(data);
                this.triggerReset();
                this.$emit("editModal",$('#edit_status').val());   
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>