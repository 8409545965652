<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' v-model="edata.id">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Shipping</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                <div id="esms">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Amount ($) <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" id='ename' step="any" min="0" required v-model="edata.amount">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Description / Note
                    </label>
                    <input v-model="edata.description" type="text" id="edescription"  class="form-control">
                </div>
                </div>
                  <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="@/assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            photo : {
                type : [String, Object , Boolean],
                default : null
            },
            edata : {
                type : [String, Object , Boolean],
                default : []
            },
            phoo : {
                type : [String, Object, Boolean],
                default : null
            },
            pho : {
                type : [String, Object, Boolean],
                default : null
            }
        },
        data(){
            return {
                tbl : "pos_shippings",
                per : "pos_shipping",
                isUpdate : false,
            }
        },
        methods: {  
            triggerReset(){
                // $('#editModal').on('hidden.bs.modal', function () {
                //     $(this).find('form').trigger('reset');
                // })
                this.isUpdate = false;
            },
            resetImg(value){
                if(value == "photo"){
                    $('#ephoto').val('');
                    this.$emit("resetImg");
                }
            },
            changeImg(value,obj){
                if(value == "photo"){
                    this.$emit("changeImg",obj);
                }
            },
            async form_update(){
                this.isUpdate = true;
                let data = new FormData();
                data.append('id',this.edata.id);
                data.append('tbl',this.tbl);
                data.append('per',this.per);
                data.append('amount',this.edata.amount ? this.edata.amount : '');
                data.append('description',this.edata.description ? this.edata.description : '');
                await frm_update(data);
                this.triggerReset();
                this.$emit("editModal",$('#edit_status').val());   
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>