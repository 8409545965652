<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Shipping</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label>
                            Amount ($) <span class="text-danger">*</span>
                        </label>
                        <input v-model="amount" type="number" step="any" min="0" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Description / Note
                        </label>
                        <input v-model="description" type="text" class="form-control">
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="@/assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'
import { user } from '@/networks/services/auth/user.service'

export default {
    data(){
        return {
            amount : '',
            tbl : "pos_shippings",
            per : "pos_shipping",
            phone : "",
            description : "",
            address : "",
            photo : '',
            pho: false,
            phoo: null,
            isCreate : false,
        }
    },
    methods: {
        triggerReset(){
            // $('#createModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
            this.isCreate = false;
            this.description = '';
            this.amount = '';
        },
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        async form_submit(){
            this.isCreate = true;
            let com_id = user('company_id');
            let data = new FormData();
            data.append('tbl',this.tbl);
            data.append('per',this.per);
            data.append('company_id',com_id);
            data.append('amount',this.amount);
            data.append('description',this.description);
            await frm_submit(data);
            this.triggerReset();
            this.resetImg("photo");
            this.$emit("createModal",$('#create_status').val()); 
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
