<template>
<form action="ePos" method="POST" id='create_form' @submit.prevent="submit()">
    <div class="receipt bg-white">
        <!-- reciept with data-->
        <!-- <div class="row mb-2">
            <div class="col-12 add-sale p-0">
                <div class="input-group h-100 p-0 m-0">
                    <div class="input-group-append">
                        <span class="input-group-text" id="customer" style="border:0px">Customer</span>
                    </div>
                    <div class="form-control h-100" style="border:0px;">
                        <select v-model="customer_id" id="product_id" class="receipt-select form-control selectPickerInvoice border-0" data-size="5" data-width="100%" data-live-search="true" required @change="selectCustomer()">
                            <option value="">Please Selete One</option>
                            <option :value="data.id" v-for="data in customers.filter(item => item.id == 1)" :key="data.id">
                                {{ data.name }}
                            </option>
                            <option :value="data.id" v-for="data in customers.filter(item => item.id != 1)" :key="data.id">
                                {{ data.name }} {{ data.phone ? `(${data.phone})` : '' }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group-append m-0">
                        <button 
                            class="btn btn-outline-system txt-system" 
                            style="border:0px" 
                            type="button" 
                            id="button-addon2" 
                            @click="loadSelectOption()"
                            data-toggle='modal' data-target='#createModal'
                        >
                            <i class="fa-solid fa-user-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 receipt-content-data pb-3 p-0 pr-3">
                <template v-if="productInvoices.length > 0">
                    <productInvoice
                        :datas="productInvoices"
                        @incrementProduct="incrementProduct($event)"
                        @decrementProduct="decrementProduct($event)"
                        @cancelProduct="cancelProduct($event)"
                        @dbClickChangeDiscount="dbClickChangeDiscount($event)"
                    />
                </template>
            </div>
        </div>
        <div class="row button-submit-sale p-0">
            <div class="col-12 m-0 p-0 mt-2">
                <table style="width:100%" class="table-detail">
                    <template v-for="data in middleInvoices" :key="data.id">
                        <tr v-if="data.id != 2" :class="`${data.id == 5 ? 'border-bot' : data.id == 1 ? 'border-tup' : ''}`">
                            <td v-if="data.id !== 6" class="text-sm">{{ data.title }}</td>
                            <td v-if="data.id !== 6" class="text-right text-sm">
                                <span v-if="customer_id != '' && data.id == 5">
                                    <!-- ({{customerInfo.customerType}} : <sub>+${{ customerInfo.amountDiscount }}</sub>) -->
                                    ({{customerInfo.customerType}} : <sub>{{ customerInfo.discount_type == '$' ? customerInfo.discount_type + ' ' + customerInfo.discount : customerInfo.discount + '' + customerInfo.discount_type + ' of $' + formatToCurrency(Number(middleInvoices[0].value) - Number(middleInvoices[3].value),2) }}</sub>)
                                </span>
                                {{
                                    customer_id != '' && data.id == 5 ? '$ ' + formatToCurrency(calculateDiscount("customerDiscount", data.value),2) : '$ ' + formatToCurrency(data.value,2)
                                }}
                            </td>
                        </tr>
                    </template>
                </table>
                <table style="width:100%" class="table-detail">
                    <tr v-for="data in middleInvoices" :key="data.id" :class="`${data.id == 5 ? 'border-bot' : data.id == 1 ? 'border-tup' : ''}`">
                        <td v-if="data.id == 6" class="text-lg txt-system">{{ data.title }}</td>
                        <td v-if="data.id == 6" class="text-lg txt-system text-right">
                            <template v-if="customer_id != ''">
                                {{  
                                    // exchangeRate ? 
                                    // formatToCurrency((exchangeRate.khr * calculateDiscount("total", data.value)),0) + ' ៛ = $ ' + formatToCurrency(calculateDiscount("total", data.value),2) : 
                                    //     '$ ' + formatToCurrency(calculateDiscount("total", data.value),2)
                                    '$' + formatToCurrency(calculateDiscount("total", data.value),2)
                                }}
                            </template>
                            <template v-else>
                                {{ (exchangeRate ? (data.value * exchangeRate.khr) > 0 ? formatToCurrency((data.value * exchangeRate.khr),0) + ' ៛ = ' : '0' + ' ៛ = ' : '')}} $ {{ data.value > 0 ? formatToCurrency(data.value,2) : '0.00' }}
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- <div :class="`${data.id == 1 ? 'col-lg-4 pl-0' : data.id == 2 ? 'col-lg-4' : 'col-lg-4 pr-0'} yeah form-group`" v-for="data in bottomInvoices" :key="data.id">
                <label :for="data.title">{{ data.title }}</label>
                <div class="input-group">
                    <input v-model="data.value" :id="data.title" type="number" step="0.01" min="0" class="form-control">
                    <div class="input-group-append">
                        <button class="input-group-text btn btn-system txt-system" type="button" @click="enterMoney(data.title, data.value)" :id="`button-addon${data.id}`"><i :class="data.icon"></i></button>
                    </div>
                </div>
            </div> -->
            <div class="col-6 pl-0">
                <label for="customerName" class="m-0">Name</label>
                <input type="text" id="customerName" v-model="customerName" rows="1" class="form-control p-2">
            </div>
            <div class="col-6 pr-0">
                <label for="phone" class="m-0">Phone <span class="text-danger">*</span></label>
                <input type="text" id="phone" v-model="phone" rows="1" class="form-control p-2" required>
            </div>
            <div class="col-12 p-0">
                <label for="location" class="m-0">Location <span class="text-danger">*</span></label>
                <textarea id="location" v-model="location" rows="1" class="form-control p-2" required></textarea>
            </div>
            <!-- shipping -->
            <div class="col-lg-4 pl-0">
                <label for="shipping" class="form-group m-0">Shipping <span class="text-danger">*</span></label>
                <select id="shipping" required class="form-control selectPickerInvoice" v-model="shipping_id" @change="enterMoney()">
                    <option value="">Please Select</option>
                    <option :value="data.id" v-for="data in shippings" :key="data.id">$ {{ data.amount }}</option>
                </select>
            </div>
            <!-- delivery company -->
            <div class="col-lg-4">
                <label for="deliveryCompany" class="form-group m-0">Delivery <span class="text-danger">*</span></label>
                <select id="deliveryCompany" required class="form-control selectPickerInvoice" v-model="delivery_id">
                    <option value="">Please Select</option>
                    <option :value="data.id" v-for="data in deliveryCompanies" :key="data.id">{{ data.name }}</option>
                </select>
            </div>
            <!-- pages  -->
            <div class="col-lg-4 pr-0">
                <label for="page" class="form-group m-0">Page <span class="text-danger">*</span></label>
                <select id="page" required class="form-control selectPickerInvoice" v-model="page_id">
                    <option :value="data.id" v-for="data in pages" :key="data.id">{{ data.name }}</option>
                </select>
            </div>
            <div class="col-4 pl-0 yeah">
                <label for="discount" class="m-0">Discount ($) <span class="text-danger">*</span></label>
                <input type="number" min="0" step="0.01" id="discount" v-model.number="discount" rows="1" class="form-control" required>
            </div>
            <!-- <div class="col-4 pl-0 yeah form-group">
                <div class="input-group p-0 m-0">
                    <div v-if="paymentMethods.length > 0" class="px-0 h-100" style="border:0px;">
                        <label for="" class="form-group m-0">Payment <span class="text-danger">*</span></label>
                        <select v-model="payment_method_id" id="product_id" class="receipt-select form-control selectPickerInvoice border-0 txt-kh" data-width="100%" required @change="payment()">
                            <option value="">Please Select Payment</option>
                            <option :value="item.id" v-for="item in paymentMethods" :key="item.id"> {{ item.name }}</option>
                        </select>
                    </div>
                </div>
            </div> -->
            <div class="col-4">
                <label for="paidStat" class="form-group m-0">Payment Status <span class="text-danger">*</span></label>
                <select id="paidStat" required class="form-control selectPickerInvoice" v-model="paidStatus">
                    <option value="">Please Select</option>
                    <option :value="data" v-for="data in 2" :key="data">{{ data == 1 ? "Paid" : "Unpaid" }}</option>
                </select>
            </div>
            <div class="col-4 pt-1 pr-0 yeah">
                <button class="btn-outline-system mt-2" :disabled="isCreate">
                    <img v-if="isCreate" src="@/assets/sniper.jpeg" alt="" width="12"> 
                    {{ isCreate ? 'Ordering...' : 'Order' }}
                </button>
            </div>
            <!-- <div class="col-12 p-0">
                <label for="note">Note</label>
                <textarea id="note" v-model="note" rows="1" class="w-100 p-2"></textarea>
            </div> -->
        </div>
    </div>
</form>
<addCustomer
:customerTypes="customerTypeData"
:code="code"
@createModal="addCustomer($event)"
/>
<!-- <confirm_order
:productInvoices="productInvoices"
:invoiceDatails="middleInvoices"
:customerInfo="customerInfo"
:exchangeRate="exchangeRate"
:paymentMethodInfo="paymentMethodInfo"
:confirmInfo="confirmInfo"
@confirmInvoiceOrder="confirmInvoiceOrder($event)"
/> -->
</template>

<script>
import productInvoice from '@/components/sale/productInvoice'
import addCustomer from '@/components/sale/addCustomer'
import confirm_order from '@/components/sale/confirm_order'
import { config } from '@/networks/config'
import axios from 'axios'
import { store } from '@/networks/services/pos/AddSalePos.service'

export default {
    emits : ['addCustomer','enterMoney','dbClickChangeDiscount','incrementProduct','decrementProduct','cancelProduct',"resetInvoiceData"],
    components :{
        productInvoice,
        addCustomer,
        confirm_order
    },
    props: {
        customers: {
            type: [Object, Array],
            default: []
        },
        code: {
            type:[Number, String],
            default : ''
        },
        productInvoices : {
            type : [Object, Array],
            default : []
        },
        middleInvoices : {
            type : [Object, Array],
            default : []
        },
        exchangeRate : {
            type : [Object],
            default : {}
        },
        paymentMethods: {
            type : [Object, Array],
            default : []
        },
        customerTypeData: {
            type : [Object, Array],
            default : []
        },
        pages: {
            type : [Object, Array],
            default : []
        },
        deliveryCompanies: {
            type : [Object, Array],
            default : []
        },
        shippings: {
            type : [Object, Array],
            default : []
        }
    },
    watch :{
        discount(after, before){
            clearTimeout(this.timing);
            if(after != '' || after != 0){
                this.$emit('enterMoney',{
                    name : 'Discount',
                    value : after
                });
            } else {
                this.$emit('enterMoney', {
                    name : 'Discount',
                    value : 0
                })
                this.timing = setTimeout(() => {
                    this.discount = 0;
                }, 500);
            }
        },
        customerName(after, before){
            this.customerInfo.customerName = after
        },
        phone(after,before){
            this.customerInfo.phone = after
        }
    },
    data() {
        return {
            timing : '',
            discount : 0,
            customer_id: 1,
            payment_method_id: 1,
            paymentMethodInfo: {
                payment_method_id : 0,
                payment_method_name : '',
            },
            customerInfo : {
                customer_id : 0,
                customerCode: 'C0000',
                customerName : '',
                customerType : '',
                discount : '',
                discount_type : '',
                amountDiscount: 0,
            },
            bottomInvoices: [
                {
                    id: 1,
                    title: "Shipping",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
                {
                    id: 2,
                    title: "Tax",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
                {
                    id: 3,
                    title: "Customer Discount",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
            ],
            confirmInfo : {
                amount : 0,
                amount_khr : 0,
            },
            paidStatus : 2,
            shipping_id : '',
            page_id : this.pages.length > 0 ? this.pages[0].id : '',
            delivery_id : '',
            isCreate : false,
            note : '',
            location : '',
            customerName : '',
            phone : ''
        }
    },
    methods: {
        async submit() {
            this.setConfirmInfo();
            this.loadSelectOption();
            if(this.exchangeRate){
                // $('#submitOrderModal').modal('show');
                await this.form_submit();
            } else {
                alertify.set('notifier','position', 'top-right');
                alertify.error(`No Exchange Rate Data`);
            }
        },
        dbClickChangeDiscount(data){
            this.$emit('dbClickChangeDiscount',data);
        },
        confirmInvoiceOrder(data){
            this.resetConfirmInfo();
            this.$emit('resetInvoiceData',data);
            this.customerInfo = {
                customer_id : 0,
                customerCode: 'C0000',
                customerName : '',
                customerType : '',
                discount : '',
                discount_type : '',
                amountDiscount: 0,
            };
            this.customer_id = '',
            this.payment_method_id = '',
            this.paymentMethodInfo = {
                payment_method_id : 0,
                payment_method_name : '',
            };
            this.bottomInvoices= [
                {
                    id: 1,
                    title: "Shipping",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
                {
                    id: 2,
                    title: "Tax",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
                {
                    id: 3,
                    title: "Customer Discount",
                    value: "0.00",
                    icon: "fa-solid fa-circle-plus"
                },
            ];
            this.shipping_id = '';
            this.note = '';
            this.location = '';
            this.customerName = '';
            this.customerName = '';
        },
        setConfirmInfo(){
            if(this.exchangeRate){
                let amount = Number(this.middleInvoices.find(item => item.id == 6).value) - Number(this.customerInfo.amountDiscount);
                this.confirmInfo = {
                    amount : Number(amount).toFixed(2),
                    amount_khr : this.formatToCurrency(Number(amount) * Number(this.exchangeRate.khr),0)
                }
            } else {
                alertify.set('notifier','position', 'top-right');
                alertify.error('Please Create Exchange Rate !');
            }
        },
        resetConfirmInfo(){
            this.confirmInfo = {
                amount : 0,
                amount_khr : 0
            }
        },
        payment(){
            let payment = this.paymentMethods.find(item => item.id == this.payment_method_id);
            this.paymentMethodInfo.payment_method_id = payment.id;
            this.paymentMethodInfo.payment_method_name = payment.name;
        },
        addCustomer(event){
            if(event.status == "success"){
                this.customer_id = event.id
                this.$emit('addCustomer', async () => {
                    this.selectCustomer();
                }).then(()=>{
                    let customer = this.customers.find(item => item.id == this.customer_id);
                    this.location = customer.address;
                });  
            }
        },
        async loadSelectOption() {
            await this.$nextTick();
            $('.selectPickerInvoice').selectpicker('refresh');
            $('.selectPickerInvoice').selectpicker();
        },
        formatToCurrency(amount,fixed = 2){
            if(fixed > 0){
                return Number(amount).toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            } else {
                let luy = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let new_luy = luy.split(".");
                return new_luy[0]; 
            }
        },
        selectCustomer(){
            if(this.customer_id == '') return 

            let customer = this.customers.find(item => item.id == this.customer_id);
            this.customerInfo.customer_id = customer.id;
            this.customerInfo.customerCode = customer.code;
            this.customerInfo.customerName = customer.name;
            this.customerInfo.customerType = customer.customer_type_name;
            this.customerInfo.discount = customer.customer_discount;
            this.customerInfo.discount_type = customer.customer_type_discount;

            this.location = customer.address;
        },
        calculateDiscount(condition,value){
            if(this.customer_id == '') return value
            if(condition == "customerDiscount"){
                let subTotal = Number(this.middleInvoices[0].value);
                let productDiscount = Number(this.middleInvoices[3].value);
                let findDiscount = 
                    this.customerInfo.discount_type == '%' ? 
                    (subTotal - productDiscount) * Number(this.customerInfo.discount) / 100 :
                    Number(this.customerInfo.discount);
                

                let final = (Number(findDiscount) + Number(value)).toFixed(2)
                this.customerInfo.amountDiscount = findDiscount.toFixed(2);

                return final;
            } else {
                let total =  Number(value) - Number(this.customerInfo.amountDiscount);
                let zero = 0;
                return total.toFixed(2) > 0 ? total.toFixed(2) : zero.toFixed(2);
            }
        },
        enterMoney(){
            // if(name == 'Shipping'){
            //     this.bottomInvoices[0].value = this.bottomInvoices[0].value == '' || this.bottomInvoices[0].value < 0 ? Number(0.00).toFixed(2) : Number(value).toFixed(2);
            // } else if(name == "Tax"){
            //     this.bottomInvoices[1].value = this.bottomInvoices[1].value == '' || this.bottomInvoices[0].value < 0 ? Number(0.00).toFixed(2)  : Number(value).toFixed(2);
            // } else {
            //     this.bottomInvoices[2].value = this.bottomInvoices[2].value == '' || this.bottomInvoices[0].value < 0 ? Number(0.00).toFixed(2)  : Number(value).toFixed(2);
            // }
            let findAmount = this.shippings.find(e => e.id == this.shipping_id);
            let data = {};
            if(findAmount){
                data = {
                    name : "Shipping",
                    value : Number(findAmount.amount)
                }
            } else {
                data = {
                    name : "Shipping",
                    value : 0
                }
            }
            this.$emit('enterMoney',data);
        },
        incrementProduct(data){
            this.$emit("incrementProduct",data)
        },
        decrementProduct(data){
            this.$emit("decrementProduct",data)
        },
        cancelProduct(data){
            this.$emit("cancelProduct",data)
        },
        async form_submit() {
            config();

            var data = new FormData();
            data.append('paid_status', this.paidStatus);
            data.append('productInvoices', JSON.stringify(this.productInvoices));
            data.append('invoiceDatails', JSON.stringify(this.middleInvoices));
            data.append('customerInfo', JSON.stringify(this.customerInfo));
            data.append('exchangeRate', JSON.stringify(this.exchangeRate));
            data.append('paymentMethodInfo', JSON.stringify(this.paymentMethodInfo));
            data.append('amount_receive_usd', 0);
            data.append('amount_receive_khr', 0);
            data.append('extra_amount_usd', 0);
            data.append('extra_amount_khr', 0);
            data.append('moneyPaid', 0);
            data.append('mustPaidUsd', this.confirmInfo.amount);
            data.append('mustPaidKhr', this.confirmInfo.amount_khr);
            data.append('debt', 0);
            data.append('note', this.note);
            data.append('photo', ' ');
            data.append('delivery_id', this.delivery_id);
            data.append('page_id', this.page_id);
            data.append('location',this.location);


            this.isCreate = true;
            const result = await axios
                                .post(`${store}`,data)
                                .catch(error => {
                                    console.log(error.response);
                                });
            this.isCreate = false;

            if(result.data.status == "success"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.confirmInvoiceOrder(result.data.data)
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } 
            else if(result.data.status == "is_permission_no"){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
            } 
            else if(result.data.status == "error"){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`); 
            } else {
                console.log(result)
            } 
        },
    },
    mounted() {
        this.selectCustomer();
        this.payment();
    }
}
</script>

<style scoped>
.button-submit-sale {
    /* នៅសល់ក្រោម 15vh */
    height: calc(100vh - 50px - 40vh);
    max-height: calc(100vh - 50px - 40vh);
    /* background-color: violet; */
    position: relative;
}

.receipt-content-data {
    width: 100%;
    /* background-color: #000000; */
    height: 40vh;
    max-height: 40vh;
    border-radius: 5px;
    overflow: scroll;
}

.overflowHidden {
    overflow: scroll !important;
}

.table-detail {
    table-layout: auto;
    box-sizing: border-box;
    font-weight: 600 !important;
    color: #1b1b28;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.font {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.table-detail tr td {
    padding: 5px 0px 5px 0px !important;
    opacity: 0.6;
}

.table-detail tr:last-child td {
    opacity: 1;
}
.bg-grey{
    background-color: rgba(163, 151, 151,0.2) !important;
    backdrop-filter: blur(33px) !important;
    background-blend-mode: overlay !important;
}
.opacity{
    backdrop-filter: blur(33px) !important;
    background-blend-mode: overlay !important;
}

.table-detail tr td:nth-last-child() {
    padding: 5px 0px 0px 0px !important;
}

@media screen and (max-width: 600px) {
    .button-submit-sale {
        /* នៅសល់ក្រោម 15vh */
        /* height: calc(100vh - 50px - 35vh); */
        /* max-height: calc(100vh - 50px - 35vh); */

        height: auto;
        min-height: auto;
    }

    .receipt-content-data {
        height: 35vh;
        max-height: 35vh;
    }
}
</style>
