<template>
  <template v-if="printInvoices">
    <header>
      <table width="100%">
        <tr>
          <td width="30%">
            <div style="text-align: end; padding-right: 20px;">
              <img :src="`${resource}${printInvoices.last_invoice.page_logo}`" style="width: 40px; height: 40px; border-radius: 50%;">
            </div>
          </td>
          <td width="70%">
            <div style="text-align: start;">
                <h2 style="letter-spacing: 1px; word-spacing: 2px; font-size: 15px; margin:0px">
                {{ printInvoices.last_invoice.page_name }}
              </h2>
              <span style="font-size:12px">
                <i class="fas fa-phone-square-alt"></i> &nbsp;
                <i>{{ printInvoices.last_invoice.page_phone }}</i>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </header>
    <table style="width:100%; padding: 0px 10px;">
      <tr style="font-size:12px; ">
        <td style="width:30%;">ឈ្មោះអ្នកទទួល</td>
        <td>៖</td>
        <td style="font-size: 12px;">{{ printInvoices.last_invoice.cus_name }}</td>
      </tr>
      <tr style="font-size:12px; ">
        <td style="width:30%;">លេខអ្នកទទួល</td>
        <td>៖</td>
        <td style="font-size: 15px;"><b>{{ printInvoices.last_invoice.cus_phone }}</b></td>
      </tr>
      <tr style="font-size:12px; ">
        <td style="width:30%; display: flex; align-content: baseline;">ទីតាំង</td>
        <td>៖</td>
        <td style="font-size: 15px;"><b>{{ printInvoices.last_invoice.location }}</b></td>
      </tr>
      <tr style="font-size:12px; "> 
        <td style="width:30%; display: flex; align-content: baseline;">តម្លៃ</td>
        <td style="font-size: 12px;" colspan="2"> ៖ {{ formatToCurrency(Number(printInvoices.last_invoice.total_usd) - Number(printInvoices.last_invoice.shipping), 2) }}$ + សេវា {{ formatToCurrency(printInvoices.last_invoice.shipping) }}$ = {{ formatToCurrency(printInvoices.last_invoice.total_usd) }}$ ({{ printInvoices.last_invoice.paid_status == 1 ? 'បានទូទាត់ប្រាក់រួចរាល់' : printInvoices.last_invoice.paid_status == 2 ? 'មិនទាន់បានទូទាត់ប្រាក់' : 'ជំពាក់ខ្លះ' }})</td>
      </tr>
      <tr style="font-size:12px; ">
        <td style="width:30%;">ដឹកជញ្ជូន</td>
        <td>៖</td>
        <td style="font-size: 12px;">{{ printInvoices.last_invoice.delivery_name }}</td>
      </tr>
      <tr style="font-size:12px; ">
        <td style="width:30%; display: flex; align-content: baseline;">Code</td>
        <td colspan="2" style="font-size: 12px;">៖ 
          <span v-for="(item, index) in printInvoices.invoiceDetails" :key="item.id">
            {{ index > 0 ? '+' : '' }} {{ item.product_code }}({{ item.quantity }}) 
          </span>
        </td>
      </tr>
    </table>
    
    <!-- <h3 style=" letter-spacing: 1px; word-spacing: 3px; padding:0px 10px; font-weight:normal; font-size: 12px;">
      សម្គាល់ ៖ {{ printInvoices.last_invoice.note }}
    </h3> -->
    
    <h2 style="text-align: center; font-weight:normal; font-size: 12px;">Thank you 🥰🥰🥰  </h2>
  </template>
</template>

<script>
import { resource } from "@/networks/domain";
export default {
  props: {
    printInvoices: {
      type: [Array, Object],
      default: [],
    },
  },
  data() {
    return {
      resource: resource,
    };
  },
  methods: {
    formatToCurrency(amount, fixed = 2) {
      if (fixed > 0) {
        return Number(amount)
          .toFixed(fixed)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        let luy = Number(amount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        let new_luy = luy.split(".");
        return new_luy[0];
      }
    },
  },
};
</script>

<style scoped>
/* table {
  width: 100% !important;
}

tr {
  width: 100% !important;
} */

h1 {
  text-align: center !important;
  vertical-align: middle !important;
}

#logo {
  width: 60% !important;
  text-align: center !important;
  -webkit-align-content: center !important;
  align-content: center !important;
  display: block !important;
  margin: 0 auto !important;
  transform: scale(0.6);
}

header {
  width: 100% !important;
  text-align: center !important;
  -webkit-align-content: center !important;
  align-content: center !important;
  vertical-align: middle !important;
}

.items thead {
  text-align: center !important;
}

.center-align {
  text-align: center !important;
}

.bill-details td {
  font-size: 12px !important;
}

.receipt {
  font-size: medium !important;
}

.items .heading {
  font-size: 12.5px !important;
  text-transform: uppercase !important;
  border-top: 1px solid black !important;
  margin-bottom: 4px !important;
  border-bottom: 1px solid black !important;
  vertical-align: middle !important;
}

.items thead tr th:first-child,
.items tbody tr td:first-child {
  width: 47% !important;
  min-width: 47% !important;
  max-width: 47% !important;
  word-break: break-all !important;
  text-align: left !important;
}

.items td {
  font-size: 8px !important;
  text-align: right !important;
  vertical-align: bottom !important;
}

.price::before {
  content: "$";
  font-family: Arial;
  text-align: right;
}

.price-riel::after {
  content: " ៛";
  font-family: Arial;
}

.price-riel {
  content: "៛";
  font-family: Arial;
  text-align: right;
}

.sum-up {
  text-align: right !important;
}

.total {
  font-size: 13px;
}

.total-bottom {
  border-bottom: 1px dashed black !important;
}

.total-top {
  border-top: 1px dashed black !important;
}

.total.text,
.total.price {
  text-align: right;
}

.total.price::before {
  content: "$";
}

.line {
  border-top: 1px solid black !important;
}

.heading.rate {
  width: 20%;
}

.dis::before {
  content: "%";
}

.heading.amount {
  width: 25%;
}

.heading.qty {
  width: 5%;
}

p {
  padding: 1px;
  margin: 0;
}

/* td {
        text-align: center !important;
    }
    th {
        text-align: center !important;
    } */

section,
footer {
  font-size: 12px;
}
/* table {
  page-break-inside: auto !important;
}
tr {
  page-break-inside: avoid !important;
  page-break-after: auto !important;
} */

</style>
