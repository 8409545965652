<template>
<div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Stock Out</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="txt-system"><u>Details</u> <span>*</span> </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="reference">
                                        Reference Code
                                    </label>
                                    <input type="text" v-model="reference" id="reference" class="form-control">
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="date">
                                        Date <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="date" id="date" type="date" class="form-control" required />
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="amount">
                                        Amount ($) <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="amount" id="amount" step="0.01" class="form-control" required>
                                </div>
                                <div class="col-lg-12 col-sm-12 form-group mb-1">
                                    <label for="description">
                                        Note / Reason <span class="text-danger">*</span>
                                    </label>
                                    <textarea v-model="description" id="description" rows="4" class="form-control" required></textarea>
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group my-2">
                                    <label for="photo">Invoice Image</label>
                                    <div class="w-100">
                                        <input id="photo" @change="changeImg('photo',$event)" type="file">
                                        <button @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                        <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="txt-system"><u>Data Entry</u> <span>*</span> </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="product_id">
                                        Product <span class="text-danger">*</span>
                                    </label>
                                    <select v-model="product_id" id="product_id" class="border-page-color selectPicker form-control txt-kh" data-live-search="true" required @change="changeProduct()">
                                        <option value="">Please Selete One</option>
                                        <option :disabled="product.onhand == 0" :value="product.id" v-for="product in products" :key="product.id">
                                            {{ product.name }} ({{ product.onhand + ' items'}})
                                            {{ product.is_sale_stock == 1 
                                                ? 
                                                    `/ (${product.onhand % product.quality_per_stock != 0 
                                                        ? 
                                                            parseFloat(product.onhand / product.quality_per_stock).toFixed(2) + ' ' + product.pos_unit_type_name 
                                                        : 
                                                            product.onhand / product.quality_per_stock + ' ' + product.pos_unit_type_name})` 
                                                : 
                                                    '' 
                                            }}
                                            {{'( ' + 'barcode :' + ' ' + product.barcode  + ' )'}}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="product_id" class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label>&nbsp;</label>
                                    <div class="form-control border-0">
                                        <div class="custom-control custom-switch">
                                            <input :required="is_item == false && is_unit_type == false" type="checkbox" :checked="is_item" class="custom-control-input" id="customSwitch1" @click="isCheckToOpen('item')">
                                            <label class="custom-control-label" for="customSwitch1">Entry as Item ?</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="is_stock" class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label>&nbsp;</label>
                                    <div class="form-control border-0">
                                        <div class="custom-control custom-switch">
                                            <input :required="is_item == false && is_unit_type == false" type="checkbox" :checked="is_unit_type" class="custom-control-input" id="customSwitch2" @click="isCheckToOpen('stock')">
                                            <label class="custom-control-label" for="customSwitch2">Entry as Unit Type ?</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div v-if="is_item" class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="quantity">Quantity <span class="text-danger">*</span> </label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="quantity" id="quantity" aria-describedby="basic-addon1" @input="checkQuantityItem(this.quantity)">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon1">items</span>
                                            <button class="btn btn-outline-success" type="button" id="button-addon1" @click="add()">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="is_unit_type" class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="quantity">Quantity <span class="text-danger">*</span> </label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="quantity" id="quantity" aria-describedby="basic-addon2" @input="checkQuantityUnitType(this.quantity)">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2">{{ products.filter(item => item.id == product_id)[0].pos_unit_type_name }}</span>
                                            <button class="btn btn-outline-success" type="button" id="button-addon2" @click="add()">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table  -->
                            <div v-if="tables.length" class="table-responsive position-relative">
                                <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
                                    <thead class="bg-system text-light">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th style="width:80px">#</th>
                                            <th>Name</th>
                                            <th style="width:80px">photo</th>
                                            <th>Item</th>
                                            <th>Unit Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in tables" :key="data.id" :id="`tr${data.id}table`">
                                            <td class="d-none"></td>
                                            <td>{{ index + 1 }}</td>
                                            <td :data-col="`#`+ (index + 1) + `. Name`">{{ data.name }}
                                                <button :data-row="`tr${data.id}table`" type="button" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                                                    <i class="fas fa-plus-circle"></i>
                                                </button>
                                            </td>
                                            <td data-col="Photo">
                                                <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                                                <span v-else>&nbsp;</span>
                                            </td>
                                            <td data-col="Item">&nbsp;{{ data.total_quantity }}</td>
                                            <td data-col="Unit Type">&nbsp;{{ data.is_stock == 1 ? data.quantity + ' ' + data.pos_unit_type_name : '' }}</td>
                                            <td :data-col="`${$t('action')}`">
                                                <button type="button" class='btn btn-danger btn-xs mr-1' title="delete" @click="deleteItem(index, data.total_quantity)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer bg-white">
                    <button @click="triggerReset()" type="button" class="btn btn-outline-dark btn-xs">
                        <i class="fas fa-sync"></i> Reset
                    </button>
                    <button id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    config
} from '@/networks/config'
import {
    resource
} from '@/networks/domain'
import {
    store
} from '@/networks/services/pos/stockOut.service'

export default {
    props: {
        products: {
            type: [Object],
            default: [],
        },
        code: {
            type: [String, Number],
            default: null
        }
    },
    data() {
        return {
            resource: resource,
            isCreate: false,
            tbl: "pos_stock_ins",
            per: "stock_in",
            product_id: '',
            reference: '',
            price: '',
            description: '',
            delivery: '',
            quantity: '',
            date: '',
            amount : '',
            tables: [],

            is_item: false,
            is_unit_type: false,
            is_stock: false,

            photo: '',
            pho: false,
            phoo: null,
        }
    },
    methods: {
        async triggerReset() {
            this.product_id = '';
            this.reference = '';
            this.price = '';
            this.description = '';
            this.delivery = '';
            this.quantity = '';
            this.date = '';
            this.amount = '';
            this.tables = []

            this.is_item = false;
            this.is_unit_type = false;
            this.is_stock = false;

            this.photo = '';
            this.pho = false;
            this.phoo = null;

            $('#photo').val('');

            this.$emit("reload");

            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');
            $('.selectPicker').selectpicker();
        },
        changeProduct() {
            if (this.product_id == '') {
                this.is_item = false;
                this.is_unit_type = false;
                this.is_stock = false;
                this.quantity = '';
            } else {
                this.is_item = true;
                this.is_unit_type = false;
                this.is_stock = false;
                this.quantity = '';
                let obj = this.products.filter(item => item.id == this.product_id)[0];
                if (obj.is_sale_stock == 1) {
                    this.is_stock = true;
                } else {
                    this.is_stock = false;
                }
            }
        },
        isCheckToOpen(value) {
            this.quantity = '';
            if (value == "item") {
                this.is_item = !this.is_item;
                this.is_unit_type = false;
            }
            if (value == "stock") {
                this.is_unit_type = !this.is_unit_type;
                this.is_item = false;
            }
        },
        resetImg(value) {
            if (value == "photo") {
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value, obj) {
            if (value == "photo") {
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        checkQuantityItem(value) {
            let product = this.products.filter(item => item.id == this.product_id)[0];
            if (this.is_item == true && Number(value) > Number(product.onhand)) {
                this.quantity = Number(product.onhand)
            }
        },
        checkQuantityUnitType(value) {
            let product = this.products.filter(item => item.id == this.product_id)[0];
            if (this.is_unit_type == true && parseFloat(value) > parseFloat(product.onhand / product.quality_per_stock).toFixed(2)) {
                this.quantity = product.onhand % product.quality_per_stock != 0 ? parseFloat(product.onhand / product.quality_per_stock).toFixed(2) : product.onhand / product.quality_per_stock;
            }
        },
        add() {
            if (this.quantity == '') return '';
            if (this.quantity == 0) return '';
            if (this.products.filter(item => item.id == this.product_id)[0].onhand == 0) return '';

            let product = this.products.filter(item => item.id == this.product_id)[0];
            let data = {};

            data.id = product.id;
            data.photo = product.photo;
            data.pos_unit_type_name = product.pos_unit_type_name;
            data.name = product.name;

            if (this.is_item) {
                data.total_quantity = this.quantity;
                data.is_stock = 0;
                this.tables.push(data);
                this.$emit('selectProduct', {
                    id: this.product_id,
                    quantity: data.total_quantity,
                    type: 'is_item'
                });
            }
            if (this.is_unit_type) {
                data.is_stock = 1;
                data.unit_type_name = this.products.filter(item => item.id == this.product_id)[0].pos_unit_type_name;
                data.quantity_per_unit_type = this.products.filter(item => item.id == this.product_id)[0].quality_per_stock;
                data.total_quantity = this.quantity == parseFloat(product.onhand / product.quality_per_stock).toFixed(2) ? product.onhand : Math.ceil(data.quantity_per_unit_type * this.quantity) > data.quantity_per_unit_type * this.quantity ? parseFloat(data.quantity_per_unit_type * this.quantity).toFixed(2) : data.quantity_per_unit_type * this.quantity;
                data.quantity = this.quantity;
                this.tables.push(data);
                this.$emit('selectProduct', {
                    id: this.product_id,
                    quantity: data.total_quantity,
                    type: 'is_unit_type'
                });
            }
            this.quantity = '';
        },
        deleteItem(key, quantity) {
            this.$emit('deleteItem', {
                id: this.tables[key].id,
                quantity: quantity
            });
            this.tables = this.tables.filter((_, index) => index !== key);
        },
        async form_submit() {
            config();

            var data = new FormData();
            data.append('amount', this.amount);
            data.append('reference', this.reference);
            data.append('description', this.description);
            data.append('out_date', this.date);
            data.append('tables', JSON.stringify(this.tables));
            data.append('photo', this.phoo);

            if (this.tables.length) {
                this.isCreate = true;
                $('#save').prop('disabled', true);

                const result = await axios
                    .post(`${store}`, data)
                    .catch(error => {
                        console.log(error.response);
                    });
                this.isCreate = false;
                $('#save').prop('disabled', false);

                this.triggerReset();

                if (result.data.status == "success") {
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    this.$emit("createModal", "success");
                } else if (result.data.status == "401") {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else if (result.data.status == "is_permission_no") {
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(`You are no permission to do this stuff!`);
                    this.$emit("createModal", "is_permission_no");
                } else if (result.data.status == "error") {
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    $("#create_status").val("error");
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(`${result.data.message}`);
                } else {
                    console.log(result)
                }
            } else {
                this.isCreate = false;
            }
        }

    },
    mounted() {
        $(document).ready(function () {
            $('.chosen2').select2();
            $('#summernote').summernote();
        });
    }
}
</script>

<style scoped>
.modal-dialog,
.modal-body {
    background-color: transparent !important;
}

.modal-content {
    background-color: #ffffff7d !important;
    backdrop-filter: blur(33px) !important;
    background-blend-mode: overlay !important;
}

.modal-header {
    border-bottom: 0px;
}
</style>
