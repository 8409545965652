<template>
      <div class="login-background">
      <div class="login-box">
      <div class="card">
        <div class="card-body login-card-body rounded-lg">
          <div class="row">
            <div class="col-12  position-absolute" style="top:-30px; left:-30px;">
             
                <!-- <img
                src="assets/img/Logos-07.png"
                :alt="$store.state.app.name"
                class="brand-image img-circle elevation-3"
                style="opacity: 0.8; width: 60px; box-shadow: 5px 10px 10px; background-color: #493ce0;"
              /> -->
          
            </div>
          </div>
          <!-- {{ $store.state.app.name }} -->
          <div class="login-logo">
            <div to="/" class="txt-system text-center">
              <b>{{$t('user_account')}}</b>
            </div>
          </div>
          <!-- <p class="login-box-msg">Sign in to start your session</p> -->
          <form action="/login" method="post" @submit.prevent="loginSubmit()">
            <div class="input-group mb-3">
              <input 
                type="text" 
                class="form-control" 
                :placeholder="$t('user_name')" 
                v-model="username"
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                :placeholder="$t('password')"
                v-model="password"
                autocomplete="on"
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div v-if="message" class="col-12 text-center">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" id="errorMessage">
                  {{message}}
                  <button type="button" class="close" @click="handleMessage()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div v-if="errors.length > 0" class="col-12 text-center">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" id="error">
                  <div v-for="(err, index) in errors" :key="index">
                    {{err}}
                    <button type="button" class="close" @click="handleAlert()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="col-8"> -->
                <!-- <div class="icheck-primary">
                  <input type="checkbox" id="remember" />
                  <label for="remember"> Remember Me </label>
                </div> -->
              <!-- </div> -->
              <div class="col-12">
                <button :disabled="islogout" id="btnLogin" type="submit" class="btn btn-login btn-block d-flex justify-content-center align-items-center position-relative">
                  <img v-if="islogout" src="../../assets/sniper.jpeg" alt="" width="12" class="mr-2">
                  <i class="fas fa-key icon-button"></i>
                  <b>{{$t('login')}}</b>
                </button>
              </div>
            </div>
          </form>
          <div class="social-auth-links text-center mt-3">
            <p class="txt-kh">Powered by <a href="https://www.oneiiotech.com/" target="_blank">Oneiiotech</a> <br> <i class="fa fa-phone-alt"></i> 016 / 017 855002 </p>
            <!-- <p class="txt-kh">Develop By Kokleng Dev</p> -->
          </div>
          <!-- <div class="social-auth-links text-center mb-2">
            <a href="https://t.me/kokleng_html" target="_blank" class="btn btn-block position-relative btn-telegram">
              <i class="fab fa-telegram-plane icon-button"></i> Telegram
            </a>
          </div>
          <div class="social-auth-links text-center mb-2">
            <a href="tel:016855002" class="btn btn-block btn-danger position-relative">
              <i class="fas fa-phone-alt icon-button"></i> Contact Us
            </a>
          </div> -->
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import axios from 'axios';
import '../../networks/services/auth/login.service.js'



export default {
    data(){
      return {
        islogin : true,
        islogout : false,
        username: '',
        password: '',
        message : '',
        errors : [],
      }
    },
    beforeMount() {
        if("posUser" in localStorage){
          this.$router.push('/');
        } else {
          if(window.history.state.back){
            if(window.history.state.back == "/logout"){
              localStorage.setItem('oldRoute',"/")
            } else {
              localStorage.setItem('oldRoute',window.history.state.back);
            }
          }
        }
    },
    methods : {
        async loginSubmit(){
            var stt = true;
            this.islogout = true;
            var data = {
              username : this.username,
              password : this.password
            };
            const RES = await axios.post('/login', data).catch(function(err){
              if(err.response){
                if(!err.response.date){
                  stt = false;
                } 
              }
            });
            if(stt){
              if(RES.data.status === 'success'){
                this.islogout = false;
                localStorage.setItem('posUser', JSON.stringify(RES.data));
                alertify.set('notifier','position', 'top-right');
                alertify.success(`Welcome ${RES.data.data.nick_name}`);

                if(localStorage.oldRoute){
                  this.$router.push(localStorage.oldRoute);
                } else {
                  this.$router.push({name : 'dashboard'});
                }
                // window.location.href = '/';
              } else if (RES.data.status === 'error') {
                this.islogout = false;
                $("#errorMessage").show();
                this.message = RES.data.message;  
              } 
              else if (RES.data.status === 401) {
                this.islogout = false;
                $("#errorMessage").show();
                this.message = RES.data.message;  
              }
              else{
                this.islogout = false;
                $("#error").show();
                this.errors = RES.data.message;
              }
            } else {
              this.$router.push({name : 'server-down'})
            }
        },
        handleAlert(){
          $("#error").hide();
        },
        handleMessage(){
          $("#errorMessage").hide();
        }
    },
    mounted(){
      // $('[data-widget="treeview"]').Treeview('init');
      //This event can be listened to via the `on()` or `once()` listener
    },
};
</script>

<style>
</style>