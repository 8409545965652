<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Customer</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label for="name">
                            Code <span class="text-danger">*</span>
                        </label>
                        <input v-model="code" type="text" id="code" class="form-control" readonly required>
                    </div>
                    <div class="form-group mb-1">
                        <label for="name">
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" id="name" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label for="phone">
                            Phone <span class="text-danger">*</span>
                        </label>
                        <input v-model="phone" type="text" id="phone" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label for="phone">
                            Address <span class="text-danger">*</span>
                        </label>
                        <input v-model="address" type="text" id="phone" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label for="customerType_id">
                            Customer Type <span class="text-danger">*</span>
                        </label>
                        <select v-model="customerType_id" id="customerType_id" class="border-page-color selectPicker form-control txt-kh" data-live-search="false" required>
                            <option value="">Please Selete One</option>
                            <option :value="customerType.id" v-for="customerType in customerTypes" :key="customerType.id"> {{ customerType.name }} ( Discount {{ customerType.discount + customerType.type }} ) </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'
import { user } from '@/networks/services/auth/user.service'

export default {
    props : {
        customerTypes : {
            type : [Object],
            default : []
        },
        code : {
            type : [String, Number],
            default : ''
        }
    },
    data(){
        return {
            name : '',
            customerType_id : '',
            tbl : "customers",
            per : "customer",
            isCreate : false,
            phone : '',
            address : '',
        }
    },
    methods: {
        triggerReset(){
            // $('#createModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
            this.name = '';
            this.phone = '';
            this.customerType_id = '';
            this.isCreate = false;
            this.address = ''
        },
        async form_submit(){
            this.isCreate = true;
            let com_id = user('company_id');
            let data = {
                name : this.name,
                customer_type_id : this.customerType_id,
                company_id : com_id,
                code : this.code,
                phone : this.phone,
                tbl : this.tbl,
                per : this.per,
                address : this.address
            };
            await frm_submit(data);
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
