<template>
<loading v-if="isTrue"/>
<div v-else-if="permission_no">No permission</div>
<div v-else class="sale-page">
    <div class="row">
        <div class="col-lg-8 col-12 product-side scroll-product">
            <SearchVue 
              @search="searchData($event)" 
              :logo="logo" 
            />
            <Categories 
              @clicker="changeCatgory($event)" 
              :categories="com_categories" 
              :category_id="company_category_id" 
            />
            <Products 
              :loadingId="loadingId" 
              :isLoading="isLoading" 
              :pageLoad="pageLoad" 
              :datas="table" 
              :productInvoices="productInvoices"
              @clickProduct="clickSale($event)" 
              @nextPage="getNextPage($event)" 
              @selectProduct="getProduct($event)"
              @incrementProduct="incrementProduct($event)"
            />
        </div>
        <div class="col-lg-4 col-12 bg-white p-0 receipt-side border-left-side">
            <receipt_no_data-vue 
              v-if="productInvoices.length <= 0"
            />
            <receiptVue 
              v-else
              :customers="customerData" 
              :productInvoices="productInvoices"
              :middleInvoices="middleInvoices"
              :exchangeRate="exchange_rate"
              :paymentMethods="payment_methods"
              :customerTypeData="customerTypeData"
              :code="code"
              :pages="pages"
              :shippings = "shippings"
              :deliveryCompanies = "deliveryCompanies"
              @enterMoney="enterMoney($event)"
              @incrementProduct="incrementProduct($event)"
              @decrementProduct="decrementProduct($event)"
              @cancelProduct="cancelProduct($event)"
              @addCustomer="addCustomer($event)"
              @resetInvoiceData="resetInvoiceData($event)"
              @dbClickChangeDiscount="dbClickChangeDiscount($event)"
            />
        </div>
    </div>
    <AttributeModalViewVue 
      v-if="create" 
      :product="productData" 
      :attributes="attributeData" 
      :product_attributes="product_attributeData" 
      :getAttributes="getAttributes"
      :productInvoices="productInvoices"
      @selectProduct="getProduct($event)"
      @incrementProduct="incrementProduct($event)"
    />
    <teleport to="#print">
      <print
      v-if="printInvoices"
      :printInvoices="printInvoices"
      />
      <p v-else >No Last Invoice Data !!!</p>
    </teleport>
</div>
</template>

<script>
import SearchVue from '@/components/sale/Search.vue';
import Categories from '@/components/sale/Categories.vue'
import Products from '@/components/sale/Products.vue'
import receipt_no_dataVue from '@/components/sale/receipt_no_data.vue';
import AttributeModalViewVue from './AttributeModalView.vue';
import receiptVue from '@/components/sale/receipt.vue';
import axios from 'axios'
import { resource } from '@/networks/domain'
import { addSalePos } from '@/networks/services/pos/AddSalePos.service';
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import print from '@/components/sale/print'
import { config } from '@/networks/config'
export default {

    components: {
        SearchVue,
        Categories,
        Products,
        receipt_no_dataVue,
        AttributeModalViewVue,
        Loading,
        receiptVue,
        print
    },
    data() {
        return {
            oldImg: '',
            isLoading: false,
            loadingId: 0,
            product_attributeData: [],
            productData: {},
            attributeData: [],
            customerData: [],
            customerTypeData: [],
            logo: '',
            products: [],
            payment_methods : [],
            exchange_rate : {},
            productInvoices : [],
            table: {
                page: 1,
                totalIndexPage: 0,
                totalItem: 0,
                per_page: 8,
                from_index: 0,
                to_index: 0,
                data: [],
                search: null,
                orderBy: 'DESC',
                fetch: false,
            },
            resource: resource,
            isCreate: false,
            is_stock: false,
            company_category_id: 'all',
            categories: [],
            com_categories: [],
            code: 0,
            unit_types: [],
            search: null,

            permission_no: false,
            isTrue: true,
            pageLoad: false,
            create: false,
            deleteAll: false,
            edit: false,
            list: false,

            //attributeModal
            getAttributes : [],

            //in invoices
            middleInvoices: [
              {id: 1,title: "Sub total (Exclude Product Discount)",value: "0.00"},
              {id: 2,title: "Tax",value: "0.00"},
              {id: 3,title: "Shipping",value: "0.00"},
              {id: 4,title: "Product Discount",value: '0.00'},
              {id: 5,title: "Customer Discount",value: "0.00"},
              {id: 6,title: "Total",value: "0.00"}
            ],
            printInvoices : [],
            shippings : [],
            pages : [],
            deliveryCompanies : [],
        }
    },
    methods: {
        dbClickChangeDiscount({discount, index, condiction}){
          let product = this.productInvoices[index];

          // if discount per item
          if(condiction){
            //change some value
            this.productInvoices[index].discount_price = parseFloat(discount);
            this.productInvoices[index].discount_type = "$";
            this.productInvoices[index].pro_discount = parseFloat(discount);
            this.productInvoices[index].total_discount = Number(discount) * Number(product.quantity);
            this.productInvoices[index].total_price = (Number(this.productInvoices[index].unit_price) * Number(product.quantity)) - Number(this.productInvoices[index].total_discount);
            this.productInvoices[index].unit_price_discount = Number(this.productInvoices[index].unit_price) - Number(discount);
          } 
          // if discount as total discount
          else {
            //change some value
            this.productInvoices[index].discount_price = parseFloat(discount) / Number(product.quantity);
            this.productInvoices[index].discount_type = "$";
            this.productInvoices[index].pro_discount = parseFloat(discount) / Number(product.quantity);
            this.productInvoices[index].total_discount = Number(discount);
            this.productInvoices[index].total_price = (Number(this.productInvoices[index].unit_price) * Number(product.quantity)) - Number(this.productInvoices[index].total_discount);
            this.productInvoices[index].unit_price_discount = Number(this.productInvoices[index].unit_price) - (Number(discount) / Number(product.quantity));
          }
          


          //load new data
          this.reloadInvoiceBottomData();
        },
        async refreshDataPrint(data){
          this.printInvoices = data;
          this.middleInvoices = [
            {id: 1,title: "Sub total (Exclude Product Discount)",value: "0.00"},
            {id: 2,title: "Tax",value: "0.00"},
            {id: 3,title: "Shipping",value: "0.00"},
            {id: 4,title: "Product Discount",value: '0.00'},
            {id: 5,title: "Customer Discount",value: "0.00"},
            {id: 6,title: "Total",value: "0.00"}
          ];
          this.productInvoices = [];
          let products = this.table.data;
          products.forEach((item,index) => {
            this.table.data[index].is_add = 0;
          })
        },
        async resetInvoiceData(data){
          await this.refreshDataPrint(data).then(() => {
            // wait something
          }).then(() => {
            setTimeout(()=>{
              window.print();
            },500)
          }).catch((err) => { console.log(err) })
        },
        searchData(value) {
            this.table.search = value
            this.pageLoad = true;
            this.table.page = 1;
            this.getData(false, false);
        },
        changeCatgory(value) {
            if(value == this.company_category_id) return
            this.company_category_id = value
            this.pageLoad = true;
            this.table.page = 1;
            this.getData(false, false);
        },
        clickSale(id) {
            this.loadingId = id;
            this.getData(false, id);
        },
        async loadSelectOption() {
          $('#belugar').attr('media',"screen");
          await this.$nextTick();
          $('.selectPickerInvoice').selectpicker('refresh');
          $('.selectPickerInvoice').selectpicker();
        },
        getNextPage(next_page) {
          this.table.page = next_page;
          this.table.fetch = true;
          this.getData(false, false);
        },
        async addCustomer(callBack){
          this.getData(false,false,true).then((data) => {
            this.customerData = data;
            this.loadSelectOption().then(() => {
              this.loadSelectOption();
            })
          }).then(() => {
            callBack();
          }).catch((err) => console.log(err));

        },
        async actionProduct(id,action){
          let index = this.table.data.findIndex(item => item.id == id);
          if(action == 'increment'){
            this.table.data[index].onhand = Number(this.table.data[index].onhand) + 1 ;
            this.table.data[index].is_add = Number(this.table.data[index].is_add) - 1 ;
          } else {
            if(this.table.data[index].onhand <= 0) return 0 ;
            this.table.data[index].onhand = Number(this.table.data[index].onhand) - 1 ;
            this.table.data[index].is_add = Number(this.table.data[index].is_add) + 1 ;
          }
          return 1;
        },
        async getProduct(data){
          this.productInvoices.push(data);
          await this.actionProduct(data.id,"decrement").then(() => {
            $('.receipt-content-data').animate({
              scrollTop: $('.receipt-content-data').get(0).scrollHeight
            }, 700);
          });
          this.reloadInvoiceBottomData();
          this.loadSelectOption();
        },
        reloadInvoiceBottomData(){
          var sub_total = 0;
          var total_price = 0;
          var product_discount = 0;

          for(let i=0; i < this.productInvoices.length ; i++){
            sub_total = Number(sub_total) + Number(this.productInvoices[i].total_price) + Number(this.productInvoices[i].total_discount);
            total_price =  Number(total_price) + Number(this.productInvoices[i].total_price);
            product_discount = Number(product_discount) + Number(this.productInvoices[i].total_discount);
          }

          total_price =  Number(total_price)  + Number(this.middleInvoices[1].value) + Number(this.middleInvoices[2].value) - Number(this.middleInvoices[4].value);

          this.middleInvoices[0].value = sub_total.toFixed(2);
          this.middleInvoices[5].value = total_price.toFixed(2);
          this.middleInvoices[3].value = product_discount.toFixed(2);
        },
        handleMiddleInvoice(data,condition){
          if(condition == "increment"){
            this.productInvoices[data.index].total_price = (Number(this.productInvoices[data.index].total_price) + Number(data.total_price)).toFixed(2);
            this.productInvoices[data.index].quantity = Number(this.productInvoices[data.index].quantity) + Number(data.quantity); 
            this.productInvoices[data.index].total_discount = (Number(this.productInvoices[data.index].total_discount) + Number(data.total_discount)).toFixed(2); 

          } else {
            this.productInvoices[data.index].total_price = (Number(this.productInvoices[data.index].total_price) - Number(data.total_price)).toFixed(2);
            this.productInvoices[data.index].quantity = Number(this.productInvoices[data.index].quantity) - Number(data.quantity); 
            this.productInvoices[data.index].total_discount = (Number(this.productInvoices[data.index].total_discount) - Number(data.total_discount)).toFixed(2); 
          }
        },
        incrementProduct(data){
          //decrement product data onhand
          this.actionProduct(data.id,"decrement").then(get => {
            if(get == 0) return ;
            // middle inovice
            this.handleMiddleInvoice(data,"increment");
            //bottom invoice
            this.reloadInvoiceBottomData();
          });
        },
        decrementProduct(data){
          // middle inovice
          this.handleMiddleInvoice(data,"decrement");
          //bottom invoice
          this.reloadInvoiceBottomData();
          //decrement product data onhand
          this.actionProduct(data.id,"increment");
          //check if quantity that increment == 0 , remove that item
          if(this.productInvoices[data.index].quantity == 0){
            this.productInvoices.splice(data.index,1);
          }

          if(this.productInvoices.length <= 0){
            for(let i = 0 ; i < this.middleInvoices.length ; i++){
              this.middleInvoices[i].value = "0.00";
            }
          }
        },
        cancelProduct(data){
          for(let i = 0 ; i < data.total_quantity ; i++){
            this.decrementProduct(data);
          }
        },
        enterMoney(data){
          switch(data.name){
            case 'Shipping':
              // update total
              if(Number(data.value) > Number(this.middleInvoices[2].value)){
                this.middleInvoices[5].value =  Number(this.middleInvoices[5].value) + Number(data.value) - Number(this.middleInvoices[2].value);
              } else if(Number(data.value) < Number(this.middleInvoices[2].value)){
                let value = Number(this.middleInvoices[2].value) - Number(data.value);
                this.middleInvoices[5].value = Number(this.middleInvoices[5].value) - Number(value);
              } else {
                return ''
              }
              //update value shipping
              this.middleInvoices[2].value = Number(data.value).toFixed(2);
              break;
            case 'Tax':
              // update total
              if(Number(data.value) > Number(this.middleInvoices[1].value)){
                this.middleInvoices[5].value =  Number(this.middleInvoices[5].value) + Number(data.value) - Number(this.middleInvoices[1].value);
              } else if(Number(data.value) < Number(this.middleInvoices[1].value)){
                let value = Number(this.middleInvoices[1].value) - Number(data.value);
                this.middleInvoices[5].value = Number(this.middleInvoices[5].value) - Number(value);
              } else {
                return ''
              }
              //update value Tax
              this.middleInvoices[1].value = Number(data.value).toFixed(2);
              break;
            default:
              //calculate update total
              if(Number(data.value) > Number(this.middleInvoices[4].value)){
                let dis = Number(data.value) - Number(this.middleInvoices[4].value);
                this.middleInvoices[5].value =  Number(this.middleInvoices[5].value) - Number(dis);
              } else if(Number(data.value) < Number(this.middleInvoices[4].value)){
                let value = Number(this.middleInvoices[4].value) - Number(data.value);
                this.middleInvoices[5].value = Number(this.middleInvoices[5].value) + Number(value);
              } else {
                return ''
              }
              //update value customer discount
              this.middleInvoices[4].value = Number(data.value).toFixed(2);
          }
          let total = Number(this.middleInvoices[5].value);
          this.middleInvoices[5].value = total.toFixed(2);
        },
        async checkIfHaveProductOnInVoice(getItem){
          let newItem = getItem;
          if(this.productInvoices.length > 0){
            for(let i = 0 ; i < this.productInvoices.length ; i++){
              // find index that match with id
              const index = newItem.findIndex(item => item.id == this.productInvoices[i].id);
              if(index != -1){
                // decrement quantity product
                newItem[index].onhand = Number(newItem[index].onhand) - Number(this.productInvoices[i].quantity);
                // the product have been added
                newItem[index].is_add = Number(newItem[index].is_add) + Number(this.productInvoices[i].quantity);
              }
            }
          }
          return newItem;
        },
        async getData(check, id, addCustomer = false) {
            if (localStorage.getItem('posUser')) {
                config();
                if (check == true) {
                    $('head').append('<link rel="stylesheet" href="/assets/style/posPrint.css">');
                }
                if (id) {
                    this.isLoading = true;
                }
                const response = await axios
                  .get(`${addSalePos(this.company_category_id,this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}`)
                  .catch(error => {
                      console.log(error)
                  });
                if (response.data.status === 'success') {
                    if(addCustomer == true){
                      this.code = response.data.customerCode;
                      return response.data.customers;
                    }
                    else if (!id) {

                        if(this.pageLoad){
                          this.table.data = [];
                        }
                        //table
                        this.table.fetch = false;
                        this.table.totalIndexPage = response.data.data.last_page;

                        const items = response.data.data.data;
                        this.checkIfHaveProductOnInVoice(items).then((res) => {
                          if (res.length > 0) {
                            for (let i = 0; i < res.length; i++) {
                                this.table.data.push(res[i])
                            }
                        }
                        }).catch((err) => {
                          console.log(err)
                        });



                        this.table.totalItem = response.data.data.total;
                        this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                        this.table.to_index = response.data.data.to ? response.data.data.to : 0;


                        //permission
                        this.list = response.data.list;
                        this.create = response.data.create;
                        this.deleteAll = response.data.delete;
                        this.edit = response.data.edit;

                        //company categories
                        this.com_categories = response.data.categories;

                        //customer data
                        this.customerData = response.data.customers;

                        //customer type data
                        this.customerTypeData = response.data.customer_types;

                        //customer code
                        this.code = response.data.customerCode;

                        //payment method
                        this.payment_methods = response.data.payment_methods;

                        //exchange rate
                        this.exchange_rate = response.data.exchange_rate;

                        //logo
                        this.logo = response.data.logo;

                        //printInvoices
                        this.printInvoices = response.data.print_invoices;


                        //shippings
                        this.shippings = response.data.shippings;

                        //delivery Company
                        this.deliveryCompanies = response.data.deliveryCompanies;

                        //pages
                        this.pages = response.data.pages;


                        this.isTrue = false;
                        this.pageLoad = false;

                        $('#checkBox').prop('checked', false);

                        this.loadSelectOption();
                  
                    } else {
                        this.isLoading = false;

                        this.product_attributeData = response.data.data.product_attributes;
                        this.attributeData = response.data.data.attributes;
                        this.productData = response.data.data.product;
                        this.getAttributes = [];

                        if (this.product_attributeData.length) {
                            $('#editModal').modal();
                        }
                    }

                } else if (response.data.status === 401) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(`${RES.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else if (response.data.status == "is_permission_no") {
                  this.isTrue = false;
                  this.permission_no = true;
                } else {
                    console.log('something wrong with api');
                }
   
            } else {
                this.$router.push('/Unauthenticated')
            }
        },
    },
    beforeMount() {
        if (!localStorage.getItem('posUser')) {
            this.$router.push('/login');
        }
    },
    created() {
        this.getData(true, false);
    },
    mounted() {

    },
    updated(){

    }
}
</script>

<style>
/* .scroll-product{
  max-height: 200px;
  overflow: scroll;
} */
.scroll-product{
    /* max-height: calc(100vh - 176px); */
    /* max-height: calc(100vh - 200px); */
    max-height: 100vh;
    overflow-y: scroll;
}
</style>
