<template>
    <nav class="mt-2">
        <ul v-if="checkNavBar == true" class="nav nav-pills nav-sidebar flex-column">
            <div v-for="(menu, index) in menus" :key="index">
                <div v-if="!menu.subItems">
                    <NavItem 
                      :permission="menu.permission" 
                      :name="menu.label" 
                      :link="menu.link" 
                      :icon="menu.icon" 
                      :dropdown="false"
                      :navBar="navBar"
                      :checkNavBar="checkNavBar"
                    />
                </div>
                <div v-if="menu.subItems">
                    <NavItem 
                      :name="menu.label" 
                      :subItems="menu.subItems" 
                      :link="menu.link" 
                      :icon="menu.icon" 
                      :dropdown="true"
                      :navBar="navBar"
                      :checkNavBar="checkNavBar"
                    />
                </div>
            </div>
        </ul>
    </nav>
</template>
<script>
import NavItem from '@/layouts/inc/NavItem'
export default {
    components: {
        NavItem,
    },
    props : {
      navBar: {
          type: [Array,Object],
          default: [],
      },
      checkNavBar: {
          type: [Boolean],
          default: false,
      },
    },
    data() {
    return {
      menus: 
      [
        {
          label: "Dashboard",
          icon: "fa fa-home",
          link: "/",
          permission : "home",
          default : "pos" 
        },
        {
          label : "Customer Management",
          icon: "fa fa-users",
          default: 'pos',
          subItems: [
            {
              label: "Customer Type",
              icon: "fa fa-angle-double-right",
              link: "/customer-type",
              permission: "customer_type"
            },
            {
              label: "Customer",
              icon: "fa fa-angle-double-right",
              link: "/customer",
              permission: "customer"
            },
          ]
        },
        {     
            label: "Sale Management",
            icon: "fa-solid fa-hand-holding-dollar",
            default: 'pos',
            subItems: [
              {
                label: "Retails (POS)",
                icon: "fa fa-angle-double-right",
                link: "/pos/add-sale",
                permission : "sale_pos" 
              }, 
              // {  
              //   label: "Wholesales",
              //   icon: "fa fa-angle-double-right",
              //   link: "/add-sale-stock",
              //   permission: "sale_stock"
              // },
              {
                label: "POS Invoices",
                icon: "fa fa-angle-double-right",
                link: "/pos-invoice",
                permission: "pos_invoice"
              }
            ]
        },
        {     
            label: "Product Management",
            icon: "fas fa-tasks",
            default: 'pos',
            subItems: [
              {         
                label: "Supplier",
                icon: "fa fa-angle-double-right",
                link: "/supplier",
                permission : "supplier"
              },
              {         
                label: "Stock In",
                icon: "fa fa-angle-double-right",
                link: "/stock-in",
                permission : "stock_in"
              },
              {
                label: "Stock Out",
                icon: "fa fa-angle-double-right",
                link: "/stock-out",
                permission: "stock_out"
              },
              {
                label: "Low Stock",
                icon: "fa fa-angle-double-right",
                link: "/low-stock",
                permission: "low_stock"
              },
              {
                label: "Out Of Stock",
                icon: "fa fa-angle-double-right",
                link: "/out-of-stock",
                permission: "out_of_stock"
              },
              {
                label: "Product",
                icon: "fa fa-angle-double-right",
                link: "/product",
                permission: "pos_product"
              },
              {         
                label: "Product Category",
                icon: "fa fa-angle-double-right",
                link: "/product-category",
                permission : "company_category"
              }, 
              {         
                label: "Product Attribute",
                icon: "fa fa-angle-double-right",
                link: "/product-attribute",
                permission : "product_attribute"
              }, 
              {
                label: "Product Discount",
                icon: "fa fa-angle-double-right",
                link: "/product-discount",
                permission: "pos_product_discount"
              },
              {
                label: "Product Barcode",
                icon: "fa fa-angle-double-right",
                link: "/pos-product-barcode",
                permission: "pos_product_barcode"
              },
              {
                label: "Unit Type",
                icon: "fa fa-angle-double-right",
                link: "/unit-type",
                permission: "pos_unit_type"
              },
              {
                label: "VAT",
                icon: "fa fa-angle-double-right",
                link: "/pos-vat",
                permission: "pos_vat"
              },
            ]
        },
        {     
          label: "Cash Management",
          icon: "fas fa-wallet",
          default: 'pos',
          subItems: [
            {         
              label: "Exchange Rate",
              icon: "fa fa-angle-double-right",
              link: "/pos-exchange-rate",
              permission : "pos_exchange"
            }, 
            {
              label: "Payment Method",
              icon: "fa fa-angle-double-right",
              link: "/payment-method",
              permission: "pos_payment_method"
            },
          ]
        },
        {     
            label: "Expense Management",
            icon: "fa-solid fa-sack-dollar",
            default: 'pos',
            subItems: [
              {
                label: "Expense Category",
                icon: "fa fa-angle-double-right",
                link: "/expense-category",
                permission : "expense_category" 
              }, 
              {  
                label: "expense",
                icon: "fa fa-angle-double-right",
                link: "/expense",
                permission: "expense"
              },
            ]
        },
        {     
            label: "Report Management",
            icon: "fa-solid fa-chart-column",
            default: 'pos',
            subItems: [
              {
                label: "Invoice Report",
                icon: "fa fa-angle-double-right",
                link: "/pos/invoice-report",
                permission : "pos_invoice_report" 
              }, 
              {
                label: "Income",
                icon: "fa fa-angle-double-right",
                link: "/pos/income-report",
                permission : "pos_income_report" 
              }, 
              {
                label: "Expense",
                icon: "fa fa-angle-double-right",
                link: "/pos/expense-report",
                permission : "pos_expense_report" 
              }, 
              {
                label: "Income & Expense",
                icon: "fa fa-angle-double-right",
                link: "/pos/income-expense-report",
                permission : "pos_income_expense_report" 
              }, 
              {
                label: "Stock In Report",
                icon: "fa fa-angle-double-right",
                link: "/pos/stock-in-report",
                permission : "pos_stock_in_report" 
              }, 
              {
                label: "Stock Out Report",
                icon: "fa fa-angle-double-right",
                link: "/pos/stock-out-report",
                permission : "pos_stock_out_report" 
              }, 
            ]
        },
        {     
            label: "Security",
            icon: "fas fa-shield-alt",
            default: 'pos',
            subItems: [
              {  
                  label: "Role",
                  icon: "fas fa-user-shield",
                  link: "/role",
                  permission : "role"
              },
              {         
                label: "User",
                icon: "fas fa-user",
                link: "/user",
                permission : "user"
              },
              {         
                label: "User Log",
                icon: "fas fa-users-cog",
                link: "/user-log",
                permission : "user_log"
              },
            ]
        },
        {
          label: "Setting",
          icon: "fas fa-cogs",
          default: 'pos',
          subItems: 
            [
              {         
                label: "My Profile",
                icon: "fas fa-user",
                link: "/about-me",
                permission : "about_me"
              }, 
              {   
                label: "Company Info",
                icon: "fas fa-info-circle",
                link: "/company-info",
                permission : "company_info"
              }, 
              {
                label: "Shipping",
                icon: "fa fa-angle-double-right",
                link: "/pos/shipping",
                permission: "pos_shipping"
              },
              {
                label: "Page",
                icon: "fa fa-angle-double-right",
                link: "/pos/page",
                permission: "pos_page"
              },
              {
                label: "Delivery Company",
                icon: "fa fa-angle-double-right",
                link: "/pos/delivery-company",
                permission: "pos_delivery_company"
              },
            ]
        },
      ]
    }
  }
}
</script>